import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../slices/userSlice';
import '../CSS/LoginModal.css'; // Keep this if you need other styles from the external CSS file

function LoginModal({ isOpen, onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }))
      .unwrap()
      .then(() => {
        onClose(); // Close the modal on successful login
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };

  if (!isOpen) {
    return null;
  }

  // Inline styles for modal-right section
  const modalRightStyle = {
    width: '50%',
    backgroundImage: `url('/static/banner/login.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    
    boxSizing: 'border-box',
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-login">
        <div className="modal-left">
          <div className="logo-login">  <div className='logo-box'>
        <img src='/static/icons/logo.png' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
        <div className="logo">
          <h1>
            <span className="title-we">Med</span>
            <div className="title-2">
              <span>Bridge</span> <span>Access</span>
            </div>
          </h1>
        </div>
      </div></div>
      <div className='login-form-modal'>
          <h2>Welcome Back</h2>
          <p className="subheading">We’re glad to see you again!</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <a href="#" className="forgot-password">Forgot Password?</a>
            <button type="submit" disabled={userState.status === 'loading'}>
              {userState.status === 'loading' ? 'Logging in...' : 'Log In'}
            </button>
          </form>
          {userState.status === 'failed' && (
            <p className="error-message">
              Error: {typeof userState.error === 'string' ? userState.error : JSON.stringify(userState.error)}
            </p>
          )}
        </div>
        </div>
        <div className="modal-right" style={modalRightStyle}>
          <div className="quote-container">
            <p className="quote-text">
              "The best way to find yourself is to lose yourself in the service of others."
            </p>
            <p className="quote-author">- Mahatma Gandhi</p>
          </div>
        </div>
        <button className="close-button-login" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
   
  );
}

export default LoginModal;
