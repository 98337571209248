import React from 'react';

function DoctorStats({ totalAppointments, pendingAppointments, canceledAppointments, previousTotal }) {
  const calculatePercentage = (current, previous) => {
    const change = ((current - previous) / previous) * 100;
    return change.toFixed(0);
  };

  const overallChange = calculatePercentage(totalAppointments, previousTotal);
  const pendingPercentage = calculatePercentage(pendingAppointments, previousTotal);
  const canceledPercentage = calculatePercentage(canceledAppointments, previousTotal);

  return (
    <div className="statistics-card">
      <div className="card-header">
        <h2>Patients</h2>
        <select className="time-select">
          <option>Monthly</option>
        </select>
      </div>
      <div className="stats-container">
        <div className="circular-progress">
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path className="circle-bg"
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className="circle"
              strokeDasharray={`${Math.abs(overallChange)}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="20.35" className="percentage">{overallChange}%</text>
          </svg>
        </div>
        <div className="stat-item total">
          <span className="stat-value">{totalAppointments}</span>
          <span className="stat-label">Total</span>
          <span className="stat-percentage">{overallChange > 0 ? '+' : ''}{overallChange}%</span>
        </div>
        <div className="stat-item pending">
          <span className="stat-value">{pendingAppointments}</span>
          <span className="stat-label">Pending</span>
          <span className="stat-percentage">{pendingPercentage > 0 ? '+' : ''}{pendingPercentage}%</span>
        </div>
        <div className="stat-item canceled">
          <span className="stat-value">{canceledAppointments}</span>
          <span className="stat-label">Canceled</span>
          <span className="stat-percentage">{canceledPercentage > 0 ? '+' : ''}{canceledPercentage}%</span>
        </div>
      </div>
    </div>
  );
}

export default DoctorStats;