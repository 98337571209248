import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchServiceDetails, resetCurrentService } from '../slices/serviceSlice';
import Loader from './Loader'; // Import the Loader component
import '../CSS/ServiceDetails.css';

const ServiceDetails = () => {
    const dispatch = useDispatch();
    const service = useSelector((state) => state.service.currentService);
    const serviceDetailsStatus = useSelector((state) => state.service.serviceDetailsStatus);
    const { serviceId } = useParams();

    useEffect(() => {
        // Reset the current service details when the component mounts or serviceId changes
        dispatch(resetCurrentService());
        
        if (serviceId) {
            dispatch(fetchServiceDetails(serviceId));
        }
    }, [serviceId, dispatch]);

    return (
        <div className="ServiceDetails">
            {serviceDetailsStatus === 'loading' ? (
                <Loader />  // Display the Loader component while loading
            ) : service ? (
                <>
                    <div className="service-header">
                        <h1>{service.heading}</h1>
                        <p>{service.one_line_description}</p>
                    </div>
                    <div className="service-details-grid">
                        {service.details.map((detail, index) => (
                            <div key={index} className="detail-card">
                                <p>{detail.content}</p>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p>No service details available.</p>
            )}
        </div>
    );
};

export default ServiceDetails;
