import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserSidebar from '../Components/UserSidebar';
import MobileHeader from '../Components/MobileHeader'; // Import the new MobileHeader component
import { Navigate } from 'react-router-dom';
import '../CSS/UserDashboard.css';
import AppointmentModal from '../Components/AppointmentModal';
import { FaPlus, FaEllipsisV } from 'react-icons/fa';
import { fetchAppointments } from '../slices/userSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.userData);
  const appointments = useSelector((state) => state.user.appointments);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const historyItems = [
    { date: '23 Dec 2020', title: 'Diagnosis: Hypertension', description: 'Diagnosed by Dr. Smith.' },
    { date: '15 May 2020', title: 'Remark: Regular exercise', description: 'Started a new exercise routine.' },
    { date: '10 Mar 2020', title: 'Issue: Frequent headaches', description: 'Reported frequent headaches.' },
  ];

  useEffect(() => {
    if (userState) {
      dispatch(fetchAppointments());
    }
  }, [dispatch, userState]);

  if (!userState) {
    return <Navigate to="/bookappointment" />;
  }

  const {
    user: { username, email, first_name, last_name },
    country,
    dob,
    gender,
    phone,
    medical_history,
    medical_reports,
  } = userState;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="dashboard">
      <MobileHeader toggleSidebar={toggleSidebar} />
      <div className='dashboard-box'>
      <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <div 
        className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`} 
        onClick={() => setIsSidebarOpen(false)}
      ></div>

      <div className='dashboard-section'>
        <div className='dashboard-left'>
          <h1 className="username">Welcome, {first_name ? first_name : username}</h1>

          <div className='appointment-cards'>
            {appointments && appointments.length > 0 ? (
              appointments.map((appointment) => (
                <div key={appointment.id} className="appointment-card">
                  <div className="doctor-info">
                    <img
                      src={appointment.doctor.profile_photo}
                      alt={`Dr. ${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`}
                      className="doctor-image"
                    />
                    <div className="doctor-details">
                      <h2>{`Dr. ${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`}</h2>
                      <p className="speciality">{`${appointment.doctor.degree}, ${appointment.doctor.country}`}</p>
                    </div>
                  </div>
                  <div className="doctor-info-2">
                    <p className="branch">{appointment.doctor.branch}</p>
                    <p className="specialization">{appointment.doctor.specialization}</p>
                  </div>
                  <div className="datetime-info">
                    <div className="datetime-pair">
                      <span className="datetime-key">Date</span>
                      <span className="datetime-value">{new Date(appointment.appointment_time).toLocaleDateString()}</span>
                    </div>
                    <div className="datetime-pair">
                      <span className="datetime-key">Time</span>
                      <span className="datetime-value">{new Date(appointment.appointment_time).toLocaleTimeString()}</span>
                    </div>
                  </div>


                  <div className="appointment-actions">
                    <span>{appointment.status}</span>
                    <div className='appointment-actions-buttons'>
                      <button>
                        <img src="/static/icons/call.png" alt="Call" />
                      </button>
                      <button>
                        <img src="/static/icons/video.png" alt="Video Call" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="appointment-card">
              <p>No appointments found.</p>
              </div>
            )}
          </div>
        </div>

        <div className='dashboard-right'>
          <h2>Make An Appointent, That Will Help You!</h2>
          <div className="actions">
            <button className="free-quote-button" onClick={() => console.log('Free Quote clicked')}>
              Free Quote
            </button>
            <button className='book-appointment-button' onClick={() => setIsModalOpen(true)}>
              Book Appointment
            </button>
          </div>

          <div className="medical-history">
      <div className="history-header">
        <h3>Medical History</h3>
        <div className="history-actions">
          <a href="#" className="see-all">See All</a>
        </div>
      </div>
      <div className="history-content">
        {historyItems.map((item, index) => (
          <div className="history-item" key={index}>
            <p className="history-date">{item.date}</p>
            <h4 className="history-title">{item.title}</h4>
            <p className="history-description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>

    <div className="medical-reports">
      <div className="reports-header">
        <h3>Medical Reports</h3>
        <FaEllipsisV className="menu-icon" />
      </div>
      <div className="reports-content">
        {medical_reports && medical_reports.map((report, index) => (
          
          <div key={index} className="report-item">
            <img src="/static/icons/file.png" alt="File Icon" className="report-icon" />
            {console.log(medical_reports )}
            <div className="report-details">
              <p className="report-name">
                {report.file.split('/').pop().split('.')[0].slice(0, 30)}...
              </p>
              <p className="report-date">{report.uploaded_at}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

        </div>

        <AppointmentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
