import React, { useState, useEffect, useRef } from 'react';
import Timeline from './Timeline';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const todayRef = useRef(null);

  useEffect(() => {
    // Scroll to today's date when the component is first rendered
    if (todayRef.current) {
      todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentDate]);

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const handleMonthChange = (event) => {
    const [year, month] = event.target.value.split('-');
    setCurrentDate(new Date(year, month - 1, 1));
  };

  const handleDateClick = (date) => {
    setCurrentDate(date);
  };

  const renderDaysAndDates = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const dates = [];
  
    // Add actual days of the current month in a grid layout
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
  
      const isToday = date.getDate() === today.getDate() &&
                      date.getMonth() === today.getMonth() &&
                      date.getFullYear() === today.getFullYear();
  
      const isSelected = date.getDate() === currentDate.getDate() &&
                         date.getMonth() === currentDate.getMonth() &&
                         date.getFullYear() === currentDate.getFullYear();
  
      dates.push(
        <div
          key={i}
          ref={isToday ? todayRef : null} // Assign ref to today's date
          className={`calendar-date ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''}`}
          onClick={() => handleDateClick(date)}
        >
          <div className="calendar-number">{i}</div>
        </div>
      );
    }
  
    return dates;
  };
  

  const generateMonthOptions = () => {
    const monthOptions = [];
    const currentYear = currentDate.getFullYear();

    for (let year = currentYear - 10; year <= currentYear + 10; year++) {
      for (let month = 0; month < 12; month++) {
        const monthDate = new Date(year, month, 1);
        monthOptions.push(
          <option key={`${year}-${month + 1}`} value={`${year}-${month + 1}`}>
            {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </option>
        );
      }
    }

    return monthOptions;
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <span className="calendar-title">Consults For Today</span>
        <div className="month-dropdown">
          <select value={`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`} onChange={handleMonthChange}>
            {generateMonthOptions()}
          </select>
        </div>
      </div>
      <div className="calendar-grid">
        {renderDaysAndDates()}
      </div>
      <Timeline selectedDate={currentDate} />
    </div>
  );
};

export default Calendar;
