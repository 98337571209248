import React from 'react'
import SignupForm from '../Components/BookAnAppointment'

function SignUp() {
  return (
    <div>
    <SignupForm  />
    </div>
  )
}

export default SignUp