import React from 'react';
import '../CSS/AboutUs.css';

const AboutUs = () => {
  return (
    <div className="senior-care-container">
      <div className="senior-care-image">
        <img src="/static/banner/banner2.jpeg" alt="Nurse with Senior" />
        <div className="play-button">
          <button>&#9654;</button>
        </div>
      </div>
      <div className="senior-care-content">
        <h2>Welcome to MedBridge Access</h2>
        <h1>Your Gateway to Premium Medical Tourism & Virtual Consultation</h1>
        <p>
          Explore a world where health and travel meet. MedBridge Access is dedicated to providing top-tier medical tourism services alongside virtual consultations for second opinions, ensuring you have the best care possible wherever you are.
        </p>
        <div className="container">
          <div className="list-column">
            <ul>
              <li>Access to world-class healthcare facilities</li>
              <li>Personalized care plans</li>
              <li>Seamless travel and medical arrangements</li>
            </ul>
          </div>
          <div className="list-column">
            <ul>
              <li>Expert second opinions from renowned specialists</li>
              <li>Comprehensive follow-up care</li>
              <li>Ease of access with a state-of-the-art virtual environment integration</li>
            </ul>
          </div>
        </div>
        <div className="senior-care-features">
          <div className="feature">
            <span className="icon"><i class="fa-solid fa-piggy-bank"></i></span>
            <span><h4>Health Solutions</h4> Affordable medical tourism</span>
          </div>
          <div className="feature">
            <span className="icon"><i class="fa-solid fa-user-doctor"></i></span>
            <span><h4>Second Opinion </h4>Validate the diagnosis & treatment.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
