import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientDetails } from '../slices/patientSlice';
import { fetchDoctorAppointmentsByDate } from '../slices/doctorSlice'; // import the thunk

import { useNavigate } from 'react-router-dom';
import '../CSS/Timeline.css';

const TimelineItem = ({ appointment, onPatientClick }) => (
  <div className="timeline__item">
    <div className="timeline__time">{new Date(appointment.appointment_time).toLocaleTimeString()}</div>
    <div className="timeline__content">
      <div className="timeline__appointment-box">
        <div className='timeline_left'> 
        <div className="timeline__title">
          <span className="timeline__dot timeline__dot--patient-checkup"></span>
          Patient Checkup
        </div>
        <div 
          className={`timeline__patient-name ${appointment.patient ? 'timeline__patient-name--clickable' : ''}`}
          onClick={() => onPatientClick(appointment.patient.id)}
        >
          Patient Name - {appointment.patient.user.first_name || 'N/A'} {appointment.patient.user.last_name || ''}
        </div>
        </div>
        <div 
          className="timeline__view-details"
          onClick={() => onPatientClick(appointment.patient.id)}
        >
          Check Patient
        </div>
      </div>
    </div>
  </div>
);

const Timeline = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointments, loading, error } = useSelector((state) => state.doctor);

  useEffect(() => {
    dispatch(fetchDoctorAppointmentsByDate(selectedDate));
  }, [selectedDate, dispatch]);

  const handlePatientClick = (patientId) => {
    dispatch(fetchPatientDetails(patientId)).then(() => {
      navigate(`/doctors/chat/${patientId}`);
    });
  };

  if (loading) return <p className="timeline__loading">Loading appointments...</p>;
  if (error) {
    const errorMessage = typeof error === 'string' ? error : error.detail || 'An error occurred while fetching appointments';
    return <p className="timeline__error">Error fetching appointments: {errorMessage}</p>;
  }

  return (
    <div className="timeline-container">
    <h4 className="timeline__header">Your Appointments</h4>
    <div className={`timeline ${appointments.length === 0 ? 'timeline--no-appointments' : ''}`}>
      {appointments.length === 0 ? (
        <p className="timeline__no-appointments">No appointments scheduled for this date.</p>
      ) : (
        appointments.map((appointment, index) => (
          <TimelineItem 
            key={index} 
            appointment={appointment} 
            onPatientClick={handlePatientClick}
          />
        ))
      )}
    </div>
  </div>

  );
};

export default Timeline;
