import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchServiceDetails, resetCurrentService } from '../slices/serviceSlice';
import Bannersmall from '../Components/Bannersmall'
import ServiceDetails from '../Components/ServiceDetails';
function ServicesDetails() {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.service.currentService);
  const serviceDetailsStatus = useSelector((state) => state.service.serviceDetailsStatus);
  const { serviceId } = useParams();
  
  useEffect(() => {
      // Reset the current service details when the component mounts or serviceId changes
      dispatch(resetCurrentService());
      
      if (serviceId) {
          dispatch(fetchServiceDetails(serviceId));
      }
  }, [serviceId, dispatch]);
    const imageUrl = '/static/banner/services.jpeg';
  const altText = 'Your Banner Description';
  return (
    <div className='Service-page'>
         
         <Bannersmall imageUrl={imageUrl} altText={altText} />
      
         <ServiceDetails />
         
    </div>
  )
}

export default ServicesDetails