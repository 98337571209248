import React from 'react';
import { FaBars } from 'react-icons/fa';
import '../CSS/MobileHeader.css'; // You'll need to create this CSS file

const MobileHeader = ({ toggleSidebar }) => {
  return (
    <div className="mobile-header">
      <div className="logo-box">
            <img src="/static/icons/logo.png" alt="Medbridge-access-logo" className="Medbridge-access-logo" />
            <div className="logo">
              <h1>
                <span className="title-we">Med</span>
                <div className="title-2"><span>Bridge</span> <span>Access</span></div>
              </h1>
            </div>
          </div>
      <button className="menu-button" onClick={toggleSidebar}>
        <FaBars />
      </button>
    </div>
  );
};

export default MobileHeader;