import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Header.css';

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <header className="header">
        <div className="header-strip">
          <p>Phone: +91 8527032951 * Email: absc@gmail.com * Time: 10:00AM-5:00PM</p>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div className="header-container">
          <div className="logo-box">
            <img src="/static/icons/logo.png" alt="Medbridge-access-logo" className="Medbridge-access-logo" />
            <div className="logo">
              <h1>
                <span className="title-we">Med</span>
                <div className="title-2"><span>Bridge</span> <span>Access</span></div>
              </h1>
            </div>
          </div>
          <nav className="nav-links">
            <Link to="/">Home</Link>
            <Link to="/aboutus">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/medicaltourism">Medical Tourism</Link>
            <Link to="/foundermessage">Founders Message</Link>
          </nav>
          <Link to="/bookappointment" className="quote-button-link">
            <button className="quote-button">Book An Appointment</button>
          </Link>
          <button className="menu-button" onClick={toggleSidebar}>
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </header>
      <div className={`header-sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-header-sidebar" onClick={closeSidebar}>
          <i className="fa fa-times"></i>
        </button>
        <nav className="header-sidebar-links">
          <Link to="/" onClick={closeSidebar}>Home</Link>
          <Link to="/aboutus" onClick={closeSidebar}>About</Link>
          <Link to="/services" onClick={closeSidebar}>Services</Link>
          <Link to="/medicaltourism" onClick={closeSidebar}>Medical Tourism</Link>
          <Link to="/foundermessage" onClick={closeSidebar}>Founders Message</Link>
          <Link to="/bookappointment" onClick={closeSidebar}>Book An Appointment</Link>
        </nav>
      </div>
      <div className={`backdrop ${sidebarOpen ? 'show' : ''}`} onClick={closeSidebar}></div>
    </>
  );
};

export default Header;
