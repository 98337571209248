import React from 'react';
import '../CSS/Footer.css'; // Assuming you have a CSS file for styling

const Footer = () => {
  const backgroundImage = '/static/banner/banner3.jpg'; // Path relative to the public folder

  return (
    <footer>
      <div className="footer-header"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <div className="footer-header-content">
          <h1>Global Healthcare Solutions You Can Trust</h1>
          <button className="contact-button">Get Started</button>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer">
          <div className="footer-content">
            <div className="footer-section about">
              <h1 className="logo-text"><span>MedBridge</span> Access</h1>
              <p>
                At MedBridge Access, we connect you with world-class healthcare providers, offering medical tourism services and expert second opinions. Your health and peace of mind are our top priorities.
              </p>
            </div>
            <div className="footer-section links">
              <h2>Quick Links</h2>
              <ul>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Our Services</a></li>
                <li><a href="#">Destinations</a></li>
                <li><a href="#">FAQs</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
            <div className="footer-section links">
              <h2>Information</h2>
              <ul>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Terms & Conditions</a></li>
                <li><a href="#">Patient Resources</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Support</a></li>
              </ul>
            </div>
            <div className="footer-section contact">
              <h2>Contact Us</h2>
              <p>
                We’re here to help you on your journey to better health.
              </p>
              <div className="contact-info">
                <p><i className="fas fa-map-marker-alt"></i> 123 Health Ave, Suite 456, MediCity</p>
                <p><i className="fas fa-phone"></i> +1-800-123-4567</p>
                <p><i className="fas fa-envelope"></i> support@medbridgeaccess.com</p>
              </div>
              <div className="socials">
                <a href="#"><i className="fab fa-facebook"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            &copy; 2024 MedBridge Access. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
