import React from 'react'
import MedicalTourismBanner from '../Components/MedicalTourismBanner'
import MedicalProcess from '../Components/MedicalProcess'
import MedicalTourismOptions from '../Components/MedicalTourismOptions'
import FAQ from '../Components/FAQ'
function MedicalTourism() {

  return (
    <div className='medical-tourism-page'>
      <MedicalTourismBanner />
      <section className="patient-survey">
        <h2>Medical Tourism Patient Survey</h2>
        <p>Medical tourism surveys reveal high patient satisfaction due to cost savings,
           access to advanced treatments, and faster care. Patients appreciate the professionalism of staff,
            modern facilities, and the chance to recover in relaxing environments. Transparency in pricing and 
            comprehensive support services further enhance the overall experience.</p>
      </section>
      <MedicalProcess />
      <MedicalTourismOptions />
      <FAQ />

    </div>
  )
}

export default MedicalTourism