import React from 'react';
import { FileText, Users, Star } from 'lucide-react';
import { useSelector } from 'react-redux'; // Import useSelector to get doctor data from Redux

const DoctorSidebar2 = () => {
  const doctor = useSelector((state) => state.doctor.doctor); // Get doctor info from Redux

  // Fallbacks in case of missing data
  const doctorName = doctor?.user?.first_name + ' ' + doctor?.user?.last_name || 'Doctor Name';
  const profilePhoto = doctor?.profile_photo || '/static/icons/profile.png';
  const specialization = doctor?.specialization || 'Specialization';
  const yearsOfExperience = doctor?.years_of_experience || 'N/A';
  const totalPatients = 0; // Example data, replace with actual data if available
  const rating = 0; // Example rating, replace with actual data if available
  const messages = []; // Sample messages, replace with actual data if available

  return (
    <div className="doctorSidebar2">
      <div className="doctorSidebar2__content">
        <div className="doctorSidebar2__profile-section">
          <div className="doctorSidebar2__avatar">
            <img src={profilePhoto} alt="Doctor" />
          </div>
          <div className="doctorSidebar2__doctor-info">
            <h2>Dr. {doctorName}</h2>
            <span>
            <p>{doctor?.degree || 'Degree Not Available'}</p>
            <p>, {specialization}</p>
            
            </span>
           
          </div>
        </div>

        {/* Total Patients Section */}
        <div className="doctorSidebar2__patients-section">
          <div className="doctorSidebar2__patients-stats">
            <div className="doctorSidebar2__stat-item">
              <div className="doctorSidebar2__icon-container doctorSidebar2__icon-container--purple">
                <Users size={30} />
              </div>
              <div className="doctorSidebar2-box">
                <span className="doctorSidebar2__stat-label">Total Patients</span>
                <span className="doctorSidebar2__stat-value">{totalPatients}</span>
              </div>
            </div>
          </div>

          <div className="doctorSidebar2__ratings-stats">
            <div className="doctorSidebar2__stat-item">
              <div className="doctorSidebar2__icon-container doctorSidebar2__icon-container--yellow">
                <Star size={30} />
              </div>
              <div className="doctorSidebar2-box">
                <span className="doctorSidebar2__stat-label">My Rating</span>
                <span className="doctorSidebar2__stat-value">{rating}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Document Section */}
        <div className="doctorSidebar2__document-section">
          <h3>Latest Document</h3>
          <div className="doctorSidebar2__document-list">
           No documents to show
          </div>
        </div>

        {/* Message Section */}
        <div className="doctorSidebar2__message-section">
          <span className="doctorSidebar2__message_heading" style={{ display: 'flex', alignItems: 'center' }}>
            <h3>Message</h3>
            <span style={{ margin: '0 6px' }}>•</span>
            <p className="doctorSidebar2__unread-count">no unread messages</p>
          </span>

          <div className="doctorSidebar2__message-list">
            {messages.map((name, index) => (
              <div key={index} className="doctorSidebar2__message-item">
                <div className="doctorSidebar2__message-avatar">
                  <span className="doctorSidebar2__avatar-initial">
                    {name.charAt(0)}
                  </span>
                </div>
                <div className="doctorSidebar2__message-content">
                  <p className="doctorSidebar2__sender-name">{name}</p>
                  <p className="doctorSidebar2__message-preview">
                    Bagaimana cara keren untuk s...
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSidebar2;
