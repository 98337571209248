import React from 'react'
import '../CSS/FounderMess.css'

function FounderMess() {
  return (
    <div>
        <div className="founder-message-background">
      <img src="/static/banner/founder-message.png" alt='founders message' className="" />
    </div>
    </div>
  )
}

export default FounderMess