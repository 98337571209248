import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../CSS/DoctorDashboard.css';
import DoctorSidebar from '../Components/DoctorSidebar';
import DashboardContent from '../Components/DashboardContent';
import DoctorSidebar2 from '../Components/DoctorSidebar2';
import MobileHeader from '../Components/MobileHeader'; // Import the new MobileHeader component

const DoctorDashboard = () => {
  const doctor = useSelector((state) => state.doctor.doctor);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 console.log(doctor)

  useEffect(() => {
    if (!doctor) {
      navigate('/doctors/login');
    }
  }, [doctor, navigate]);

  if (!doctor) {
    return null; // or a loading spinner
  }
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
 


  return (
   <div className='doctor-dashboard'>
         <MobileHeader toggleSidebar={toggleSidebar} />
     
      <div className='dashboard-box'>
      <DoctorSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div 
        className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`} 
        onClick={() => setIsSidebarOpen(false)}
      ></div>
    <DashboardContent />
    <DoctorSidebar2 />
    </div>
      </div>
  );
};

export default DoctorDashboard;
